import mixins from 'vue-typed-mixins'
import DefaultButton from '@/calendesk/sections/section/components/DefaultButton.vue'
import CServicesField from '@/calendesk/sections/section/booking/components/CServicesField.vue'
import { DateTimeFormats } from '@/calendesk/models/DateTimeFormats'
import SelectableService from '@/calendesk/models/SelectableService'
import Employee from '@/calendesk/models/DTO/Response/Employee'
import Service from '@/calendesk/models/DTO/Response/Service'
import Dialog from '@/calendesk/models/Dialog'
import { DialogTypes } from '@/components/dialogs/DialogTypes'
import DialogSize from '@/calendesk/models/DialogSize'
import truncate from '@/calendesk/filters/truncate'
import ServiceType from '@/calendesk/models/DTO/Response/ServiceType'
import ServiceLocation from '@/calendesk/models/DTO/Response/ServiceLocation'
import CommonBookingActions from '@/calendesk/sections/section/shared/mixins/CommonBookingActions'

export default mixins(CommonBookingActions).extend({
  components: {
    DefaultButton,
    CServicesField
  },
  data () {
    return {
      calendarInitialFinished: false,
      selectedEmployeeIds: [] as Array<number>,
      selectedEmployeeId: null as number | null,
      selectedLocation: null as ServiceLocation | null,
      selectableEmployees: [] as Array<Record<string, string>>,
      selectedServices: [] as SelectableService[],
      isLoading: false,
      weekday: [1, 2, 3, 4, 5, 6, 0],
      userRequestedEmployee: false
    }
  },
  mounted () {
    this.initCalendar()
  },
  computed: {
    isLocationRequired (): boolean {
      return this.appConfiguration.schedulesV2enabled && this.serviceLocations.length > 0
    },
    showLocationError (): boolean {
      return this.isLocationRequired && !this.selectedLocation
    },
    showServiceDescriptionInCalendar (): boolean {
      return (this.selectedSelectableService &&
        this.selectedSelectableService.service.description &&
        this.data.configuration.wb_show_service_description__checkbox__)
    },
    calendarInitialServiceSelectionEnabled (): boolean {
      return !!this.data.configuration.wb_initial_service_selection__checkbox__
    },
    calendarInitialServiceSelectionSupported (): boolean {
      return this.calendarInitialServiceSelectionEnabled &&
      (this.filteredServices.length > 1 ||
      (this.filteredServices.length === 1 && this.filteredServices[0].types && this.filteredServices[0].types.length > 0))
    },
    getTruncatedServiceDescription (): string | null {
      if (this.selectedSelectableService && this.selectedSelectableService.service) {
        const description = truncate(this.selectedSelectableService.service.description as string, 150)
        return this.replaceUrlsWithLinks(description as string)
      }

      return null
    }
  },
  methods: {
    initCalendarWithService (selectedSelectableService: SelectableService) {
      if (this.serviceEmployees.length === 1) {
        this.selectedEmployeeId = null
      }

      this.calendarInitialFinished = true
      this.selectedSelectableService = selectedSelectableService

      this.$nextTick(() => {
        if (this.selectedSelectableService) {
          const element = document.querySelector('.flexible-booking-calendar')
          if (element) {
            element.scrollIntoView({ behavior: 'auto', block: 'start' })
          }
        }
      })
    },
    calendarPrev () {
      this.selectedDate = this.$moment(this.selectedDate, DateTimeFormats.FULL)
        .subtract(7, 'days')
        .format(DateTimeFormats.FULL)
    },
    calendarNext () {
      this.selectedDate = this.$moment(this.selectedDate, DateTimeFormats.FULL)
        .add(7, 'days')
        .format(DateTimeFormats.FULL)
    },
    employeeById (employeeId: number): Employee | null {
      const employee = this.serviceEmployees.find(
        (employee: Employee) => employee.id === employeeId
      )

      if (employee) {
        return employee
      }

      return null
    },
    selectFirstServiceAsDefault () {
      if (this.filteredServices && this.filteredServices.length > 0) {
        const firstService = this.filteredServices[0]
        let serviceType = null
        if (firstService.types && firstService.types.length > 0) {
          serviceType = firstService.types[0]
        }

        this.selectedSelectableService = new SelectableService(
          firstService,
          serviceType,
          this.filteredServices.length
        )
      }
    },
    selectAllEmployees () {
      this.selectedEmployeeIds = this.serviceEmployees.map((employee: Employee) => {
        return employee.id
      })
    },
    openDescriptionDialog (service: Service, serviceType: ServiceType|null) {
      let title = service.name

      if (serviceType) {
        title += `(${serviceType.name})`
      }

      this.openDialog(
        new Dialog(
          true,
          DialogTypes.BOOKING_SERVICE_DESCRIPTION_DIALOG,
          title,
          DialogSize.SMALL,
          false,
          'center',
          {
            description: service.description
          }
        )
      )
    },
    goToInitCalendarPage () {
      this.calendarInitialFinished = false
      this.firstDateSet = false

      this.$nextTick(() => {
        if (this.selectedSelectableService) {
          const element = document.querySelector('.flexible-calendar-init-services')
          if (element) {
            element.scrollIntoView({ behavior: 'auto', block: 'start' })
          }
        }
      })
    }
  }
})
